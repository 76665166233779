
import image2 from "../assets/t-image2.jpg";
import  anmol from "../assets/anmol.jpeg";
import noni from "../assets/noni.png"
import akash from "../assets/akash.png";
import negi from '../assets/negi.png';

export const testimonialsData = [


  {
    image: akash,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body and help other people to achve!",
    name: 'AKASH',
    status : 'COACH'
  },

  {
    image: noni,
    review:
      "I made the right choice by choosing the Fitclub and transform myslef !",
    name: 'NAMAN',
    status : 'CLIENT'
  },
  {
    image: anmol,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'ammi',
    status : 'COACH'
  },




  
  {
    image: image2,
    review: 'I have made right choice and make my body fit',
    name: 'JOHN KEVIN',
    status: 'CLIENT'
  },
  {
    image : negi,
    review:' I mayank negi transform myself within 3 months love to be member of this gym thanks to my coach and team',
    name: 'NEGI',
    status: "CLIENT"
  }
];
